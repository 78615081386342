const pomloApp = {};

// Define constants
pomloApp.BREAKPOINTS = {
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1140,
};
pomloApp.DEFAULT_DURATION = 300;
pomloApp.HEADER_BACKGROUND_HEIGHT = 50;
pomloApp.HEADER_HEIGHT = 115;

pomloApp.scrollTo = (selector) => {
  jQuery(selector).animatescroll({
    scrollSpeed: 600,
    padding: pomloApp.HEADER_HEIGHT,
    easing: 'easeInOutQuad',
  });
};

pomloApp.toggleHeaderStyle = () => {
  function setSmallHeader() {
    jQuery('body').addClass('header--small');
    jQuery('#navbar-container').addClass('navbar--small');
  }

  function unsetSmallHeader() {
    jQuery('body').removeClass('header--small');
    jQuery('#navbar-container').removeClass('navbar--small');
  }

  if ((jQuery(window).width() < pomloApp.BREAKPOINTS.MD) || (jQuery(window).scrollTop() > pomloApp.HEADER_HEIGHT)) {
    setSmallHeader();
  } else {
    unsetSmallHeader();
  }

  if ((jQuery(window).scrollTop() > pomloApp.HEADER_BACKGROUND_HEIGHT)) {
    jQuery('header').addClass('header--bg-1');
  } else {
    jQuery('header').removeClass('header--bg-1');
  }
};

jQuery(document).ready(() => {
  pomloApp.toggleHeaderStyle();

  // Init interface
  jQuery('#navbar__toggler--open, #navbar__toggler--close').click(() => {
    jQuery('#navbar__mobile').toggleClass('active');
    if (jQuery('#navbar__mobile').hasClass('active')) {
      jQuery('body').addClass("overflow-hidden");
    } else {
      jQuery('body').removeClass("overflow-hidden");
    }
  });

  jQuery('.scroll-to').click((event) => {
    event.preventDefault();
    const scrollLink = jQuery(event.target).data('target');
    pomloApp.scrollTo(scrollLink);
    if (jQuery('#navbar__mobile').hasClass('active')) {
      jQuery('#navbar__mobile').removeClass('active');
    }
  });
});

jQuery(window).scroll(() => {
  pomloApp.toggleHeaderStyle();
});

jQuery(window).resize(() => {
  if (jQuery(window).width() >= pomloApp.BREAKPOINTS.LG) {
    jQuery('#navbar__mobile').removeClass('active');
  }
  pomloApp.toggleHeaderStyle();
});
